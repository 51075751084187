h5, .h5,
h6, .h6 {
    font-family: var(--font-bold-family);
    font-weight: var(--font-bold-weight);
}

h1 strong, .h1 strong,
h2 strong, .h2 strong,
h3 strong, .h3 strong,
h5 strong, .h5 strong,
h6 strong, .h6 strong,
h1 .strong, .h1 .strong,
h2 .strong, .h2 .strong,
h3 .strong, .h3 .strong,
h5 .strong, .h5 .strong,
h6 .strong, .h6 .strong {
    font-family: var(--font-bold-family);
    font-weight: var(--font-bold-weight);
}

h1, .h1 {
    text-transform: uppercase;
}