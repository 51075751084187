.expandable-panel__header-text > .sub {
    font-family: var(--font-bold-family);
    font-weight: var(--font-bold-weight);
}

@media screen and (max-width: 767px) {
    .expandable-panel__icon {
        font-size: 35px;
        line-height: 54px;
    }
}

@media screen and (min-width: 768px) {
    .expandable-panel__icon {
        line-height: 95px;
        font-size: 60px;
    }

    .expandable-panel__header-text {
        color: var(--color-body-text);
    }
}

.expandable-panel__icon .icon-warenkorb {
    font-size: 45px;
}