.pimcore_area_portal-bus-journey-slide .teaser__body {
    min-height: 200px;
}

/* backgrounds */
.section.bg-green {
    padding-top:20px;
    background-color:var(--color-primary);
}
.section.bg-yellow {
    padding-top:20px;
    background-color:var(--color-secondary);
}
.section.bg-grey {
    padding-top:20px;
    background-color:var(--color-info-light);
}
@media screen and (min-width: 768px) {
    .section.bg-green {
        padding:60px 0 40px 0;
    }
    .section.bg-yellow {
        padding:60px 0 40px 0;
    }
    .section.bg-grey {
        padding:60px 0 40px 0;
    }
}