:root {
    --img-poi-bg-color: var(--color-primary);
    --img-poi-bg-color-fallback: #000;
    --img-poi-bg-active: var(--color-primary);
}
.img-poi {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: var(--color-secondary);
    color: #ffffff;
    box-shadow:  0 0 0 5px rgba(0, 130, 68, 0.6);
}
.img-poi__content {
    display: block;
    position: absolute;
    bottom: 42px;
    right: -3px;
    background: var(--img-poi-bg-color-fallback);
    background: var(--img-poi-bg-color);
    white-space: nowrap;
    padding: 3px 15px 2px 15px;
    border-radius: 0px;
    border-bottom: 3px solid var(--img-poi-bg-color);
}
.img-poi__content:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(243,229,41,.9);
    border-bottom-right-radius: 115px 12px;
}
.img-poi__content:after {
    content: '';
    display: block;
    position: absolute;
    top: 35px;
    right: 0px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 11px 0 11px;
    border-color: var(--img-poi-bg-color-fallback) transparent transparent transparent;
    border-color: var(--img-poi-bg-color) transparent transparent transparent;
}
.img-poi__content-text {
    position: relative;
    color: var(--color-primary);
}
@media screen and (min-width: 768px) {
    .img-slide .owl-dot {
        line-height: 33px;
    }
}
/*hover*/
a.img-poi:hover {
    box-shadow:  0 0 0 5px var(--img-poi-bg-active);
}
a.img-poi:hover>.img-poi__content,
a.img-poi:hover>.img-poi__content:before,
a.img-poi:focus>.img-poi__content,
a.img-poi:focus>.img-poi__content:before,
a.img-poi:hover .img-poi__content-text,
a.img-poi:focus .img-poi__content-text{
    background: var(--img-poi-bg-active);
    color: var(--color-primary-comp);
}
a.img-poi:hover>.img-poi__content:after,
a.img-poi:focus>.img-poi__content:after {
    border-color: var(--img-poi-bg-active) transparent transparent transparent;
}
a.img-poi:hover, a.img-poi:focus {
    box-shadow:  0 0 0 5px var(--img-poi-bg-active);
}

.img-poi:focus { outline: none; }