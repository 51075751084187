/*elements to hide till styles.css arrived*/
.sidebar {visibility: visible;}

@import 'web/static/css/style/variables.css';
@import 'web/static/css/style/base.css';
@import 'web/static/css/style/base-layout.css';
@import 'web/static/css/style/busreisen/icons.css';
@import 'web/static/css/style/btn.css';

@import 'web/static/css/style/navbar-main.css';
@import 'web/static/css/style/navbar-main-mobile.css';
@import 'web/static/css/style/navbar-main-dropdown.css';
@import 'web/static/css/style/meta-navigation.css';
@import 'web/static/css/style/busreisen/meta-navigation.css';
@import 'web/static/css/style/footer-main.css';
@import 'web/static/css/style/footer-sprite.css';
@import 'web/static/css/style/busreisen/footer-main.css';
@import 'web/static/css/style/teaser.css';
@import 'web/static/css/style/busreisen/teaser.css';
@import 'web/static/css/style/teaser-slider.css';
@import 'web/static/css/style/img-teaser.css';
@import 'web/static/css/style/console.css';
@import 'web/static/css/style/img-slide.css';
@import 'web/static/css/style/busreisen/img-slide.css';
@import 'web/static/css/style/busreisen/img-poi.css';
@import 'web/static/css/style/btn-cta.css';
@import 'web/static/css/style/form.css';
@import 'web/static/css/style/checkbox.css';
@import 'web/static/css/style/panel.css';
@import 'web/static/css/style/pagination.css';

@import 'web/static/css/style/hotline-slide.css';

@import 'web/static/css/style/nav-tree.css';
@import 'web/static/css/style/breadcrumb.css';
@import 'web/static/css/style/wysiwyg.css';
@import 'web/static/css/style/busreisen/wysiwyg.css';
@import 'web/static/css/style/floated-item.css';
@import 'web/static/css/style/table.css';
@import 'web/static/css/style/gallery.css';
@import 'web/static/css/style/hr.css';
@import 'web/static/css/style/busreisen/hr.css';
@import 'web/static/css/style/video-asset.css';
@import 'web/static/css/style/content-teaser.css';
@import 'web/static/css/style/hover-zoom.css';
@import 'web/static/css/style/label.css';
@import 'web/static/css/style/accordion.css';
@import 'web/static/css/style/search-result.css';
/*@import 'web/static/css/style/sommercard-teaser.css';*/
@import 'web/static/css/style/google-map.css';
@import 'web/static/css/style/google-map-infobox.css';
@import 'web/static/css/style/datepicker.css';
@import 'web/static/css/style/lightbox.css';
@import 'web/static/css/style/socialmashup.css';
@import 'web/static/css/style/star-rating.css';
@import 'web/static/css/style/status-icon.css';
@import 'web/static/css/style/horizontal-list-group.css';
@import 'web/static/css/style/webshop-item.css';
@import 'web/static/css/style/popover.css';
@import 'web/static/css/style/weather.css';
@import 'web/static/css/style/partner-slide.css';
@import 'web/static/css/style/interactive-map.css';
@import 'web/static/css/style/progressbar.css';
@import 'web/static/css/style/highlight-slide.css';
@import 'web/static/css/style/content-slide.css';
@import 'web/static/css/style/busreisen/plus-punkte-slide.css';

/*Portal*/
@import 'web/static/css/style/expandable-panel.css';
@import 'web/static/css/style/busreisen/expandable-panel.css';
@import 'web/static/css/style/map-slide.css';
@import 'web/static/css/style/sommercard.css';


@import 'web/static/css/style/layout.css';
@import 'web/static/css/style/typo.css';
@import 'web/static/css/style/busreisen/typo.css';
@import 'web/static/css/style/oocss.css';
@import 'web/static/css/style/cookies.css';
@import 'web/static/css/style/shariff.css';

@import 'web/static/css/style/busreisen/variables.css';