.decorated-hr {
    position: relative;
    margin:0;
    padding:0;
    height:74px;
}
.decorated-hr:before, .decorated-hr:after {
    content:' ';
    display: block;
    position: absolute;
    top:36px;
    left: 0;
    width: 90px;
    height:2px;
    background:var(--color-primary);
}
.decorated-hr:after {
    left: auto;
    right:0;
}
.decorated-hr .icon-trennelement:before {
    margin-left:5px;
}
.decorated-hr__left, .decorated-hr__right {
    display: none;
}
@media screen and (min-width: 768px) {
    .decorated-hr:before, .decorated-hr:after {
        width:290px;
    }
    .col-lg-6 .decorated-hr:before, .col-lg-6 .decorated-hr:after {
        width:230px;
    }
}