.slide--plus-punkte {
    position: relative;
    text-align: center;
    padding: 10px 0 30px 0;
    z-index: 2;
    background-position: center center !important;
    background-size: cover !important;
}
.slide--plus-punkte:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.85;
    background-color: #ffed00;
    background: linear-gradient(to bottom, #ffed00 0, #fecb00 100%);
    z-index: -1;
}
.slide--plus-punkte .plus-punkt-badge {
    background: url(/static/img/busreisen/pluspunkt-badge.png) center center no-repeat;
    background-size: contain;
    width: 60px;
    height: 70px;
    padding-top: 15px;
    margin-bottom: 15px;
    display: inline-block;
    clear: both;
}
.slide--plus-punkte .btn-primary {
    display: block;
}
@media screen and (min-width: 768px) {
    .slide--plus-punkte .plus-punkt-badge {
        padding-top: 8px;
    }
    .slide--plus-punkte {
        padding: 25px 0 60px 0;
    }
    .slide--plus-punkte__badge-row > div {
        display: inline-block;
        float: none;
        vertical-align: top;
        margin: 0 -2px;
    }
}